import React, { memo } from 'react';
import { map, get } from 'lodash';
import NewsItem from './NewsItem';
import { Icon } from '../../Icon';
import styles from './index.module.scss';

interface Props {
  data: any[];
  footer?: string | JSX.Element;
  onClickItem?(...arg: any[]): void;
}

const NewsList = (props: Props): JSX.Element => {
  const { data, footer, onClickItem = () => {} } = props;

  return (
    <div className={styles.newsListContainer}>
      <div className={styles.newsList}>
        {
          map(data, (newItem, idx): JSX.Element => {
            return (
              <NewsItem
                key={get(newItem, 'articleId', idx)}
                className={styles.newsItem}
                data={newItem}
                showDetail={idx <= 1}
                onClick={onClickItem}
              />
            );
          })
        }
      </div>
      <div className={styles.footer}>
        <div className={styles.right}>
          { footer }
          <Icon
            type="iconzhankai"
            className={styles.icon}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(NewsList);