import React, { memo } from 'react'
import { map, get } from 'lodash';
import { getValue } from '@@/utils';
import timeAxisHeader from '@@/assets/timeAxisHeader.png';
import timeAxisFooter from '@@/assets/timeAxisFooter.png';
import MemorabiliaItem, { Props as ItemProps } from './MemorabiliaItem';
import styles from './index.module.scss';

interface Props {
  data: ItemProps[];
  onClickItem?(...arg: any[]): void;
}

const MemorabiliaList = (props: Props): JSX.Element => {
  const { data, onClickItem = (): void => {} } = props;

  return (
    <div className={styles.memorabiliaList}>
      <div className={styles.header}>
        <div
          className={styles.headerImg}
          style={{ backgroundImage: `url(${timeAxisHeader})` }}
        />
      </div>
      <div className={styles.main}>
        {
          map(data, (item, idx): JSX.Element => {
            return (
              <div
                key={get(item, 'id', idx)}
                className={styles.itemWrapper}
              >
                <MemorabiliaItem
                  className={styles.item}
                  {...item}
                  id={getValue(item, 'articleId', 0)}
                  onClick={onClickItem}
                />
              </div>
            );
          })
        }
      </div>
      <div className={styles.footer}>
        <div
          className={styles.footerImg}
          style={{ backgroundImage: `url(${timeAxisFooter})` }}
        />
      </div>
    </div>
  );
};

export default memo(MemorabiliaList);