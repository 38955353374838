import { memo } from 'react';

interface Props {
  isLoading: any,
  error: any,
}

const Loading = ({ isLoading, error }: Props): null => {
  return null;
};

export default memo(Loading);