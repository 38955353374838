import React, { memo } from 'react';
import styles from './index.module.scss';

interface Props {
  imgUrl: string;
}

const Card = (props: Props): JSX.Element => {
  const { imgUrl } = props;

  return (
    <div
      className={styles.card}
    >
      <img src={imgUrl} alt="" />
    </div>
  );
};

export default memo(Card);