import React, { PureComponent } from 'react';
import { Carousel } from 'antd';
import banner2 from '@@/assets/Banner/banner2.jpg';
import banner3 from '@@/assets/Banner/banner3.jpg';
import banner4 from '@@/assets/Banner/banner4.jpg';
import banner5 from '@@/assets/Banner/banner5.png';
import banner6 from '@@/assets/Banner/banner6.png';
import banner7 from '@@/assets/Banner/banner7.png';
import styles from './index.module.scss';

class Banner extends PureComponent {
  onChange() {
    console.log('onChange')
  }

  render() {
    return (
      <Carousel afterChange={this.onChange} autoplay>
        <div>
          <img className={styles.bannerItem} src={banner5} alt="" />
        </div>
        <div>
          <img className={styles.bannerItem} src={banner6} alt="" />
        </div>
        <div>
          <img className={styles.bannerItem} src={banner7} alt="" />
        </div>
      </Carousel>
    )
  }
}

export default Banner;