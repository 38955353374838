import React, { PureComponent } from 'react';
import { Breadcrumb } from 'antd';
import { mapData } from './utils';
import styles from './index.module.scss';

function itemRender(jumpFunc: any, route: any, params: any, routes: any, paths: any): JSX.Element {
  const last = routes.indexOf(route) === routes.length - 1;

  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <span
      className={styles.canClick}
      onClick={() => { jumpFunc(route.path) }}
    >
      {route.breadcrumbName}
    </span>
  );
}

interface Props {
  routes?: any[];
  history?: any;
}

@mapData
class MyBreadcrumb extends PureComponent<Props> {
  jumpFunc = (path: string) => {
    const { history } = this.props;
    history.push(path);
  };

  render(): JSX.Element {
    const { routes } = this.props;
    return (
      <Breadcrumb
        className={styles.breadcrumb}
        itemRender={itemRender.bind(this, this.jumpFunc)}
        routes={routes}
        separator=">"
      />
    );
  }
}

export default MyBreadcrumb;