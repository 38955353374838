import { get, isString, forEach } from 'lodash';

function getUrlParam(string?: string) {
  const { location } = window;
  let search = get(location, 'search', '');
  const result: any = {};

  if (!isString(search)) {
    return '';
  }

  search = search.substring(1);
  const searchArr = search.split('&');

  forEach(searchArr, (param) => {
    if (/([^=]*)=(.*)/.test(param)) {
      if (RegExp.$2) {
        result[RegExp.$1] = RegExp.$2;
      }
    }
  });

  if (isString(string)) {
    return result[string];
  }
  return result;
}

export default getUrlParam;