import React, { memo } from 'react';
import { map, get } from 'lodash';
import Card from './Card';
import styles from './index.module.scss';

interface Props {
  data: any[];
}

const CardList = (props: Props): JSX.Element => {
  const { data } = props;

  return (
    <div className={styles.cardList}>
      {
        map(data, (item, idx): JSX.Element => {
          return (
            <Card
              key={get(item, 'id', idx)}
              imgUrl={get(item, 'img', '')}
            />
          );
        })
      }
    </div>
  );
};

export default memo(CardList);