import React, { PureComponent } from 'react';
import { Menu, message } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { get, map, isString } from 'lodash';
import { Banner, Search } from '@@/components';
import { getUrlParam } from '@@/utils';
import logoImg from '@@/assets/logo.png';
import MobileMenu from './Menu/Mobile';
import { fixSelectedKey } from './utils';
import styles from './index.module.scss';

const navData = [
  { key: '/', text: '首页' },
  { key: '/news', text: '新闻咨讯' },
  { key: '/aboutUs', text: '关于我们' },
  { key: '/message', text: '信息批露' },
  { key: '/sister', text: '阳光姐姐' },
  { key: '/contactUs', text: '联系我们' },
];



interface Props extends RouteComponentProps {
  history: any;
}

interface State {
  selectedKey: string;
}

class Header extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const defaultSelectedKey = fixSelectedKey(get(props.location, 'pathname', ''));

    this.state = {
      selectedKey: defaultSelectedKey || '',
    };
  }

  handleChangePage = (item: any): void => {
    const { history } = this.props;
    const targetPath = get(item, 'key', '/');
    this.setState({
      selectedKey: fixSelectedKey(targetPath),
    });
    history.push(targetPath);
  };

  goToSearchPage = (url: string) => {
    const { location, history } = this.props;
    this.setState({
      selectedKey: '/',
    }, () => {
      console.log(this.state.selectedKey);
    });
    if (location.pathname === '/search') {
      history.replace(url);
    } else {
      history.push(url);
    }
  };

  onSearch = (val: string): void => {
    if (isString(val) && val.trim()) {
      this.goToSearchPage(`/search?searchText=${encodeURIComponent(val)}&p=1`);
    } else if(isString(val)) {
      message.error('请输入搜索内容');
    }
  };

  render() {
    const { selectedKey } = this.state;
    const defaultSearchValue = decodeURIComponent(getUrlParam('searchText') || '');

    return (
      <div className={styles.header}>
        <div className={styles.nav}>
          <div className={styles.left}>
            <img className={styles.logo} src={logoImg} alt="" />
            <div className={styles.title}>浙江宜尔阳光教育基金会</div>
            <div className={styles.subTitle}>YEER EDUCATION FOUNDATION</div>
          </div>
          <div className={styles.right}>
            <Menu
              mode='horizontal'
              className={styles.pcMenu}
              onSelect={this.handleChangePage}
              selectedKeys={selectedKey ? [selectedKey] : []}
            >
              {
                map(navData, (navItem, idx) => {
                  return (
                    <Menu.Item
                      key={get(navItem, 'key', idx)}
                    >
                      { get(navItem, 'text', '') }
                    </Menu.Item>
                  );
                })
              }
            </Menu>
            <MobileMenu
              className={styles.mobileMenu}
            />
          </div>
        </div>
        <div className={styles.bannerWrapper}>
          <Banner />
          <div className={styles.searchWrapper}>
            <Search
              onSearch={this.onSearch}
              defaultValue={defaultSearchValue}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);