import React, { memo, useCallback, useState, useRef, useEffect } from 'react';
import { Pagination, Button, Input } from 'antd';
import { set, isFunction } from 'lodash';
import classNames from 'classnames';
import { getValue } from '@@/utils';
import styles from './index.module.scss';

interface Props {
  className?: string;
  defaultPageSize?: number;
  total: number;
  defaultCurrent?: number;
  onChange?(page: number): void;
  curPage?: number;
}

const PaginationCustom = (props: Props): JSX.Element => {
  const {
    className,
    defaultPageSize = 5,
    total,
    defaultCurrent = 1,
    onChange,
    curPage = 1,
  } = props;

  const [curPageState, setCurPage] = useState(defaultCurrent);
  const [targetPage, setTargetPage] = useState(1);
  const asData = useRef({});

  useEffect(() => {
    setCurPage(curPage);
  }, [curPage]);

  const itemRender = useCallback((current: number, type: string, originalElement: JSX.Element) => {
    if (type === 'prev') {
      return <Button>上一页</Button>;
    }
    if (type === 'next') {
      return <Button>下一页</Button>;
    }
    return originalElement;
  }, []);

  const onPageChange = useCallback((page) => {
    setCurPage(page);
    if (isFunction(onChange)) {
      onChange(page);
    }
  }, [onChange]);

  const onInputChange = useCallback((e) => {
    const page = Number(e.target.value);
    if (!isNaN(page)) {
      setTargetPage(page);
    }
  }, []);

  useEffect(() => {
    set(asData.current, 'targetPage', targetPage);
  }, [targetPage]);

  const jumpToPage = useCallback(() => {
    const page = Number(getValue(asData.current, 'targetPage'));
    if (page) {
      setCurPage((asData.current as any).targetPage);

      if (isFunction(onChange)) {
        onChange(page);
      }
    }
  }, [onChange]);

  return (
    <div className={classNames(styles.pagination, className)}>
      <Pagination
        defaultPageSize={defaultPageSize}
        total={total}
        showSizeChanger={false}
        itemRender={itemRender}
        defaultCurrent={defaultCurrent}
        current={curPageState}
        onChange={onPageChange}
      />
      <Input
        className={styles.pageInput}
        value={targetPage}
        size="small"
        onChange={onInputChange}
      />
      <Button
        className={styles.confirmBtn}
        onClick={jumpToPage}
      >
        确定
      </Button>
    </div>
  );
};

export default memo(PaginationCustom);