import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import { getValue } from '@@/utils';
import Icon from '../../../Icon/Icon';
import styles from './index.module.scss';

interface Props {
  articleId?: number;
  title?: string;
  coverImageList?: any[];
  pubdateText?: string;
  className?: string;
  onClick?(...arg: any[]): void;
}

const EventsAxiosItem = (props: Props): JSX.Element => {
  const {
    articleId = '',
    title = '',
    coverImageList = [],
    pubdateText = '',
    className = '',
    onClick = () => {},
  } = props;

  const img = getValue(coverImageList, '0.imageUrl');

  const handleClick = useCallback(() => {
    onClick(articleId);
  }, [articleId, onClick]);

  return (
    <div className={classNames(styles.eventsAxiosItem, className)}>
      <div className={styles.date}>
        { pubdateText }
      </div>
      <div className={styles.line} />
      <div className={styles.title}>
        { title }
      </div>
      <div
        className={styles.img}
        style={{ backgroundImage: `url(${img})` }}
      />
      <div className={styles.iconWrapper}>
        <Icon
          type="iconplay1"
          className={styles.icon}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default memo(EventsAxiosItem);