import React, { memo } from 'react';
import { Route } from 'react-router-dom';
import ContactUs from './ContactUs';
import styles from './index.module.scss';



interface Props {

}

const Footer = (props: Props): JSX.Element => {
  return (
    <div className={styles.footer}>
      <Route
        exact
        path="/"
        component={ContactUs}
      />
      <div className={styles.copyrightWrapper}>
        <div className={styles.copyright}>
          <span>Copyright&copy;浙江宜尔阳光教育基金会</span>
          <span>设计制作：杭州画语科技有限公司</span>
        </div>
      </div>
    </div>
  );
};

export default memo(Footer);