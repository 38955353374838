import React, { memo } from 'react';
import qrCodeImg from '@@/assets/qrCode.jpg';
import ContactUsComp from './ContactUsComp';
import styles from './index.module.scss';



interface Props {

}

const ContactUs = (props: Props): JSX.Element => {
  return (
    <div className={styles.contactUs}>
      <div className={styles.left}>
        <div className={styles.title}>联系我们</div>
        <ContactUsComp styleType="filled" className={styles.contactUsComp} />
      </div>
      <div className={styles.right}>
        <div className={styles.title}>关注我们</div>
        <div
          className={styles.code}
        >
          <img src={qrCodeImg} alt="" />
        </div>
        <div className={styles.codeMsg}>
          阳光姐姐全球志愿者联盟
        </div>
      </div>
    </div>
  )
};

export default memo(ContactUs);
