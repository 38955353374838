import React, { PureComponent } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import { Loading } from '@@/components';

class AppRouter extends PureComponent {
  render() {
    return (
      <BrowserRouter>
        {/* _layout */}
        <Route
          path='/'
          component={
            Loadable({
              loader: () => import(/* webpackChunkName: 'basicLayout' */'@@/layouts/BasicLayout'),
              loading: Loading,
            })
          }
        />
      </BrowserRouter>
    );
  }
}

export default AppRouter;