import React, { memo, useCallback } from 'react';
// import { getDates } from '@@/utils';
import styles from './index.module.scss';

export interface Props {
  id: string | number;
  title: string;
  videocontent: string;
  pubdateText: number;
  onClick?(...arg: any[]): void;
}

const VideoItem = (props: Props): JSX.Element => {
  const { id, title, videocontent, pubdateText, onClick = () => {} } = props;

  // const {
  //   year, month, date
  // } = getDates(dateTime);
  //
  // const dateStr = `${year}年${month}月${date}日`;
  const handleClick = useCallback((): void => {
    onClick(id);
  }, [id, onClick]);

  return (
    <div className={styles.videoItem} onClick={handleClick}>
      <video src={videocontent} className={styles.video}>
      </video>
      <div className={styles.title}>{title}</div>
      <div className={styles.date}>{pubdateText}</div>
    </div>
  );
};

export default memo(VideoItem);