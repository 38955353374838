import React, { PureComponent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { get } from 'lodash';
import styles from './utils.module.scss';

const mapper: Record<string, any> = {
  '/news': {
    title: {
      eng: 'NEWS',
      chinese: '新闻咨讯',
    },
    nav: [
      {
        text: '新闻站',
        selected: true,
      },
      {
        text: '视频站',
        path: '/news/videos',
      }
    ],
  },
  '/news/detail/:id': {
    title: {
      eng: 'NEWS',
      chinese: '新闻咨讯',
    },
    nav: [
      {
        text: '新闻站',
        selected: true,
      },
      {
        text: '视频站',
        path: '/news/videos',
      }
    ],
  },
  '/news/videos': {
    title: {
      eng: 'NEWS',
      chinese: '新闻咨讯',
    },
    nav: [
      {
        text: '新闻站',
        path: '/news',
      },
      {
        text: '视频站',
        selected: true,
      }
    ],
  },
  '/news/videos/detail/:id': {
    title: {
      eng: 'NEWS',
      chinese: '新闻咨讯',
    },
    nav: [
      {
        text: '新闻站',
        path: '/news',
      },
      {
        text: '视频站',
        selected: true,
      }
    ],
  },
  '/aboutUs': {
    title: {
      eng: 'ABOUT US',
      chinese: '关于我们',
    },
    nav: [
      {
        text: '简介',
        selected: true,
      },
      {
        text: '负责人简介',
        path: '/aboutHeader',
      }
    ],
  },
  '/aboutHeader': {
    title: {
      eng: 'ABOUT US',
      chinese: '关于我们',
    },
    nav: [
      {
        text: '简介',
        path: '/aboutUs',
      },
      {
        text: '负责人简介',
        selected: true,
      }
    ],
  },
  '/message': {
    title: {
      eng: <div className={styles.messageTitle}>INFORMATION<br/>DISCLOSUE</div>,
      chinese: '信息披露',
    },
    nav: [
      {
        text: '管理制度',
        selected: true,
      },
      {
        text: '财务报告',
        path: '/message/report',
      },
      {
        text: '基金会文件',
        path: '/message/files',
      }
    ],
  },
  '/message/detail/:id': {
    title: {
      eng: <div className={styles.messageTitle}>INFORMATION<br/>DISCLOSUE</div>,
      chinese: '信息披露',
    },
    nav: [
      {
        text: '管理制度',
        selected: true,
      },
      {
        text: '财务报告',
        path: '/message/report',
      },
      {
        text: '基金会文件',
        path: '/message/files',
      }
    ],
  },
  '/message/report': {
    title: {
      eng: <div className={styles.messageTitle}>INFORMATION<br/>DISCLOSUE</div>,
      chinese: '信息披露',
    },
    nav: [
      {
        text: '管理制度',
        path: '/message',
      },
      {
        text: '财务报告',
        selected: true,
      },
      {
        text: '基金会文件',
        path: '/message/files',
      }
    ],
  },
  '/message/report/detail/:id': {
    title: {
      eng: <div className={styles.messageTitle}>INFORMATION<br/>DISCLOSUE</div>,
      chinese: '信息披露',
    },
    nav: [
      {
        text: '管理制度',
        path: '/message',
      },
      {
        text: '财务报告',
        selected: true,
      },
      {
        text: '基金会文件',
        path: '/message/files',
      }
    ],
  },
  '/message/files': {
    title: {
      eng: <div className={styles.messageTitle}>INFORMATION<br/>DISCLOSUE</div>,
      chinese: '信息披露',
    },
    nav: [
      {
        text: '管理制度',
        path: '/message',
      },
      {
        text: '财务报告',
        path: '/message/report',
      },
      {
        text: '基金会文件',
        selected: true,
      }
    ],
  },
  '/message/files/detail/:id': {
    title: {
      eng: <div className={styles.messageTitle}>INFORMATION<br/>DISCLOSUE</div>,
      chinese: '信息披露',
    },
    nav: [
      {
        text: '管理制度',
        path: '/message',
      },
      {
        text: '财务报告',
        path: '/message/report',
      },
      {
        text: '基金会文件',
        selected: true,
      }
    ],
  },
  '/sister': {
    title: {
      eng: 'SISTER SUNSHINE',
      chinese: '阳光姐姐',
    },
    nav: [
      {
        text: '阳光故事',
        selected: true,
        children: [
          {
            text: '大事记',
            selected: true,
          },
          {
            text: '温情时刻',
            path: '/sister/tender',
          }
        ],
      },
      {
        text: '志愿者申请',
        children: [
          {
            text: '标准',
            path: '/sister/standard',
          },
          {
            text: '通道与流程',
            path: '/sister/flow',
          }
        ],
      }
    ],
  },
  '/sister/detail/:id': {
    title: {
      eng: 'SISTER SUNSHINE',
      chinese: '阳光姐姐',
    },
    nav: [
      {
        text: '阳光故事',
        selected: true,
        children: [
          {
            text: '大事记',
            selected: true,
          },
          {
            text: '温情时刻',
            path: '/sister/tender',
          }
        ],
      },
      {
        text: '志愿者申请',
        children: [
          {
            text: '标准',
            path: '/sister/standard',
          },
          {
            text: '通道与流程',
            path: '/sister/flow',
          }
        ],
      }
    ],
  },
  '/sister/tender': {
    title: {
      eng: 'SISTER SUNSHINE',
      chinese: '阳光姐姐',
    },
    nav: [
      {
        text: '阳光故事',
        selected: true,
        children: [
          {
            text: '大事记',
            path: '/sister',
          },
          {
            text: '温情时刻',
            selected: true,
          }
        ],
      },
      {
        text: '志愿者申请',
        children: [
          {
            text: '标准',
            path: '/sister/standard',
          },
          {
            text: '通道与流程',
            path: '/sister/flow',
          }
        ],
      }
    ],
  },
  '/sister/tender/detail/:id': {
    title: {
      eng: 'SISTER SUNSHINE',
      chinese: '阳光姐姐',
    },
    nav: [
      {
        text: '阳光故事',
        selected: true,
        children: [
          {
            text: '大事记',
            path: '/sister',
          },
          {
            text: '温情时刻',
            selected: true,
          }
        ],
      },
      {
        text: '志愿者申请',
        children: [
          {
            text: '标准',
            path: '/sister/standard',
          },
          {
            text: '通道与流程',
            path: '/sister/flow',
          }
        ],
      }
    ],
  },
  '/sister/standard': {
    title: {
      eng: 'SISTER SUNSHINE',
      chinese: '阳光姐姐',
    },
    nav: [
      {
        text: '阳光故事',
        children: [
          {
            text: '大事记',
            path: '/sister',
          },
          {
            text: '温情时刻',
            path: '/sister/tender',
          }
        ],
      },
      {
        text: '志愿者申请',
        selected: true,
        children: [
          {
            text: '标准',
            selected: true,
          },
          {
            text: '通道与流程',
            path: '/sister/flow',
          }
        ],
      }
    ],
  },
  '/sister/flow': {
    title: {
      eng: 'SISTER SUNSHINE',
      chinese: '阳光姐姐',
    },
    nav: [
      {
        text: '阳光故事',
        children: [
          {
            text: '大事记',
            path: '/sister',
          },
          {
            text: '温情时刻',
            path: '/sister/tender',
          }
        ],
      },
      {
        text: '志愿者申请',
        selected: true,
        children: [
          {
            text: '标准',
            path: '/sister/standard',
          },
          {
            text: '通道与流程',
            selected: true,
          }
        ],
      }
    ],
  },
};

interface Props extends RouteComponentProps {

}

export function mapData(WrapperComp: any): any {
  return withRouter(class extends PureComponent<Props> {
    render() {
      const { match } = this.props;
      const pathname: string = get(match, 'path', '');
      const data = mapper[pathname];

      return (
        <WrapperComp {...this.props} {...data} />
      );
    }
  })
}