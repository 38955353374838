import React, { memo } from 'react';
import { map, get } from 'lodash';
import { getValue } from '@@/utils';
import LineItem, { Props as LineItemProps } from './LineItem';
import styles from './index.module.scss';

interface Props {
  list: LineItemProps[];
  onClickItem?(...arg: any[]): any;
}

const LineList = (props: Props): JSX.Element => {
  const { list, onClickItem = (): void => {} } = props;

  return (
    <div className={styles.lineList}>
      {
        map(list, (item, idx): JSX.Element => {
          return (
            <LineItem
              key={get(item, 'id', idx)}
              {...item}
              id={getValue(item, 'articleId', 0)}
              onClick={onClickItem}
            />
          );
        })
      }
    </div>
  );
};

export default memo(LineList);