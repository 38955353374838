import React, { memo } from 'react';
import { map } from 'lodash';
import classNames from 'classnames';
import { Icon } from '../../../Icon/index';
import styles from './index.module.scss';



interface Props {
  className?: string;
  msg: (string | JSX.Element)[];
  icon: string;
}

const Card = (props: Props): JSX.Element => {
  const { className = '', msg, icon } = props;

  return (
    <div className={classNames(styles.card, 'card', className)}>
      <Icon
        className={classNames(styles.icon, 'icon')}
        type={icon}
      />
      <div className={classNames(styles.msg, 'msg')}>
        {
          map(msg, (item, idx): JSX.Element => {
            return (
              <p key={idx}>
                { item }
              </p>
            );
          })
        }
      </div>
    </div>
  );
};

export default memo(Card);