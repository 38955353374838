import React, { memo } from 'react';
import { map, get } from 'lodash';
import classNames from 'classnames';
import { getValue } from '@@/utils';
import NewsStationItem from './NewsStationItem';
import styles from './index.module.scss';

interface Props {
  data: any[];
  className?: string;
  onClickItem?(...arg: any[]): void;
}

const NewsStationList = (props: Props): JSX.Element => {
  const { data, className, onClickItem = () => {} } = props;

  return (
    <div className={classNames(styles.newsStationList, className)}>
      {
        map(data, (item, idx): JSX.Element => {
          return (
            <NewsStationItem
              key={get(item, 'articleId', idx)}
              {...item}
              id={getValue(item, 'articleId')}
              onClick={onClickItem}
            />
          )
        })
      }
    </div>
  );
};

export default memo(NewsStationList);