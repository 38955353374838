import React, { memo } from 'react';
import { map, get } from 'lodash';
import { getValue } from '@@/utils';
import VideoItem, { Props as ItemProps } from './VideoItem';
import styles from './index.module.scss';

interface Props {
  list: ItemProps[];
  onClickItem?(...arg: any[]): void;
}

const VideosList = (props: Props): JSX.Element => {
  const { list, onClickItem = () => {} } = props;

  return (
    <div className={styles.videosList}>
      {
        map(list, (item, idx): JSX.Element => {
          return (
            <VideoItem
              key={get(item, 'articleId', idx)}
              {...item}
              id={getValue(item, 'articleId', '')}
              onClick={onClickItem}
            />
          )
        })
      }
    </div>
  );
};

export default memo(VideosList);