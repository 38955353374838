import React, { memo, useCallback } from 'react';
import { getDates, getValue } from '@@/utils';
import { MoreIcon } from '@@/components';
import styles from './index.module.scss';

export interface Props {
  id: string | number;
  title: string;
  pubdate: string | number;
  onClick?(...arg: any[]): any;
  type?: number;
}

const LineItem = (props: Props): JSX.Element => {
  const { id, title, pubdate, onClick = (): void => {} } = props;

  const {
    year,
    month,
    date,
  } = getDates(pubdate);

  const dateStr = `${year}年${month}月${date}日`;

  const onclickItem = useCallback((): void => {
    const type = getValue(props, 'type');
    onClick(id, type);
  }, [onClick, id, props]);

  return (
    <div className={styles.lineItem} onClick={onclickItem}>
      <div className={styles.title}>{ title }</div>
      <div className={styles.right}>
        <div className={styles.date}>{ dateStr }</div>
        <MoreIcon
          type="large"
          className={styles.icon}
        />
      </div>
    </div>
  );
};

export default memo(LineItem);