import React, { memo, useCallback } from 'react';
import { map } from 'lodash';
import classNames from 'classnames';
import { getValue } from '@@/utils';
import styles from './index.module.scss';

export interface Props {
  id: string | number;
  title?: string;
  year?: string;
  date?: string;
  coverImageList?: string[];
  className?: string;
  onClick?(...arg: any[]): void;
}

const MemorabiliaItem = (props: Props): JSX.Element => {
  const { id, title, year, date, coverImageList, className, onClick = () => {} } = props;

  const handleClick = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  return (
    <div className={classNames(styles.item, className)} onClick={handleClick}>
      <div className={styles.date}>
        <div>{date}</div>
        <div className={styles.line} />
        <div>{year}</div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          { title }
        </div>
        <div
          className={styles.imgList}
        >
          {
            map(coverImageList, (img, idx): JSX.Element => {
              return (
                <div
                  key={idx}
                  className={styles.img}
                  style={{ backgroundImage: `url(${getValue(img, 'imageUrl', '')})` }}
                />
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

export default memo(MemorabiliaItem);