export function fixSelectedKey(path: string) {
  if (/^\/news\/detail/.test(path)) {
    return '/news';
  }

  if (/^\/news\/videos\/detail/.test(path)) {
    return '/news';
  }

  if (/^\/message\/detail/.test(path)) {
    return '/message';
  }

  if (/^\/message\/report\/detail/.test(path)) {
    return '/message';
  }

  if (/^\/message\/files\/detail/.test(path)) {
    return '/message';
  }

  if (/^\/sister\/detail/.test(path)) {
    return '/sister';
  }

  if (/^\/sister\/tender\/detail/.test(path)) {
    return '/sister';
  }

  switch(path)  {
    case '/news/videos': // 新闻视频站
      return '/news';
    case '/message/report':
      return '/message';
    case '/message/files':
      return '/message';
    case '/sister/tender':
      return '/sister';
    case '/sister/standard':
      return '/sister';
    case '/sister/flow':
      return '/sister';
    case '/aboutHeader':
      return '/aboutUs';
    case '/search':
      return '/';
    default:
      return path;
  }
}