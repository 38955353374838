import React, { memo, useCallback } from 'react';
import { getValue } from '@@/utils';
import { MoreIcon } from '../../../Icon';
import styles from './index.module.scss';

interface Props {
  id: string;
  year: string;
  date: string;
  title: string;
  content: string;
  coverImageList: any[];
  onClick?(...arg: any[]): void;
}

const NewsStationItem = (props: Props): JSX.Element => {
  const { id, date, title, year, coverImageList, onClick = () => {} } = props;
  let { content = '' } = props;
  // let year = '';
  // let month_day = '';
  // if (/^([\d]*)年([\d]*月[\d]*日)/.test(date)) {
  //   // year = RegExp.$1;
  //   month_day = RegExp.$2;
  // }
  content = String(content).replace(/<img[^>]*>/g, '');

  const img = getValue(coverImageList, '0.imageUrl', '');

  const handleClickItem = useCallback((): void => {
    onClick(id);
  }, [id, onClick]);

  return (
    <div className={styles.newStationItem}>
      <div className={styles.date}>
        <div className={styles.year}>
          { year }
        </div>
        <div className={styles.monthDay}>
          { date }
        </div>
      </div>
      <div className={styles.itemMain}>
        <div className={styles.title}>
          { title }
        </div>
        <div className={styles.content}>
          <div
            className={styles.img}
            style={{ backgroundImage: `url(${img})` }}
          />
          <div
            className={styles.detail}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        <div className={styles.footer}>
          <MoreIcon type="large" onClick={handleClickItem} />
        </div>
      </div>
    </div>
  );
};

export default memo(NewsStationItem);