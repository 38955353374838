import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import { getValue } from '@@/utils';
import MoreIcon from '../../../Icon/MoreIcon/index';
import styles from './index.module.scss';

interface Props {
  data: {
    coverImageList: any[];
    title: string;
    content: string;
    pubdateText: string;
  };
  showDetail: boolean;
  className?: string;
  onClick?(...arg: any[]): void;
}

const NewsItem = (props: Props): JSX.Element => {
  const { data, showDetail, className = '', onClick = () => {} } = props;
  const img = getValue(data, 'coverImageList.0.imageUrl', '');
  const title = getValue(data, 'title', '');
  const content = String(getValue(data, 'content', '')).replace(/<img[^>]*>/g, '');
  const time = getValue(data, 'pubdateText', '');

  const handleClickItem = useCallback((): void => {
    const id = getValue(data, 'articleId');
    onClick(id)
  }, [data, onClick]);

  if (showDetail) {
    return (
      <div className={classNames(styles.newsItem, styles.showDetail, className)}>
        <div
          className={styles.img}
          style={{ backgroundImage: `url(${img})` }}
        />
        <div className={styles.msg}>
          <div className={styles.title}>
            <span className={styles.left}>{title}</span>
            <span className={styles.right}>
              <span className={styles.time}>{time}</span>
              <MoreIcon onClick={handleClickItem} />
            </span>
          </div>
          <div
            className={styles.detail}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(styles.newsItem, styles.line, className)}>
      <div className={styles.title}>
        <span className={styles.left}>{title}</span>
        <span className={styles.right}>
          <span className={styles.time}>{time}</span>
          <MoreIcon onClick={handleClickItem} />
        </span>
      </div>
    </div>
  );
};

export default memo(NewsItem);