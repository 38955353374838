import repairSingleDigit from './repairSingleDigit';

/**
 * 获取年、月、日
 * @param data
 * @returns {{year: any; month: string | number; date: string | number}}
 */
function getDates(data?: any) {
  const date = new Date(data);
  return {
    year: date.getFullYear(),
    month: repairSingleDigit(date.getMonth() + 1),
    date: repairSingleDigit(date.getDate()),
  }
}

export default getDates;