import React, { memo } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

interface Props {
  type?: 'small' | 'large';
  className?: string;
  onClick?(...arg: any[]): void;
}

const MoreIcon = (props: Props): JSX.Element => {
  const { type = 'small', className, onClick = () => {} } = props;

  return (
    <div
      className={classNames(styles.more, type === 'large' ? styles.large : '', className)}
      onClick={onClick}
    >
      more+
    </div>
  );
};

export default memo(MoreIcon);