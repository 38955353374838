import React, { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { Menu, Button } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import { withRouter, RouteComponentProps } from 'react-router';
import { map, get } from 'lodash';
import classNames from 'classnames';
import { getValue } from '@@/utils';
import { fixSelectedKey } from '@@/components/Header/utils';

import styles from './index.module.scss';

const navData = [
  { key: '/', text: '首页' },
  {
    key: 'submenu-/news', text: '新闻咨讯',
    children: [
      {
        key: '/news',
        text: '新闻站',
      },
      {
        key: '/news/videos',
        text: '视频站',
      }
    ],
  },
  {
    key: 'submenu-/aboutUs', text: '关于我们',
    children: [
      {
        key: '/aboutUs',
        text: '简介',
      },
      {
        key: '/aboutHeader',
        text: '负责人简介',
      }
    ],
  },
  {
    key: 'submenu-/message', text: '信息批露',
    children: [
      {
        key: '/message',
        text: '管理制度',
      },
      {
        key: '/message/report',
        text: '财务报告',
      },
      {
        key: '/message/files',
        text: '基金会文件',
      },
    ],
  },
  {
    key: '/sister', text: '阳光姐姐',
    children: [
      {
        key: '/sister',
        text: '大事记',
      },
      {
        key: '/sister/tender',
        text: '温情时刻',
      },
      {
        key: '/sister/standard',
        text: '标准',
      },
      {
        key: '/sister/flow',
        text: '通道与流程',
      }
    ],
  },
  { key: '/contactUs', text: '联系我们' },
];

interface Props extends RouteComponentProps {
  className?: string;
}

const MobileMenu = (props: Props): JSX.Element => {
  const { className, location, history } = props;

  const [collapsed, setCollapsed] = useState(false);

  const [selectedKey, setSelectedKey] = useState('');

  useEffect(() => {
    setSelectedKey(fixSelectedKey(get(location, 'pathname', '')));
  }, [location]);

  const toggleCollapsed = useCallback(() => {
    setCollapsed((prev): boolean => {
      return !prev;
    })
  }, []);

  const MoreButton = useMemo((): JSX.Element => {
    return (
      <Button type="primary" className={styles.moreBtn} onClick={toggleCollapsed}>
        {
          collapsed
            ? (
              <MenuUnfoldOutlined />
            )
            : (
              <MenuFoldOutlined />
            )
        }
      </Button>
    );
  }, [collapsed, toggleCollapsed]);

  const handleChangePage = useCallback((item: any) => {
    const targetPath = get(item, 'key', '/');
    setSelectedKey(fixSelectedKey(targetPath));
    history.push(targetPath);
  }, [history]);

  const MenuComp = useMemo((): JSX.Element => {
    return (
      <Menu
        onSelect={handleChangePage}
        selectedKeys={selectedKey ? [selectedKey] : []}
        mode="inline"
      >
        {
          map(navData, (navItem, idx) => {
            const children = getValue(navItem, 'children', []);
            if (getValue(children, 'length', 0) > 0) {
              return (
                <Menu.SubMenu
                  title={getValue(navItem, 'text', '')}
                  key={getValue(navItem, 'key', idx)}
                >
                  {
                    map(children, (navItem2, idx2) => {
                      return (
                        <Menu.Item
                          key={getValue(navItem2, 'key', idx2)}
                        >
                          { getValue(navItem2, 'text', '') }
                        </Menu.Item>
                      )
                    })
                  }
                </Menu.SubMenu>
              );
            }
            return (
              <Menu.Item
                key={get(navItem, 'key', idx)}
              >
                { get(navItem, 'text', '') }
              </Menu.Item>
            );
          })
        }
      </Menu>
    );
  }, [selectedKey, handleChangePage]);

  return (
    <div className={classNames(styles.mobileMenu, className)} style={{ position: 'relative' }}>
      { MoreButton }
      <div className={classNames(styles.dropdown, collapsed && styles.collapsed)}>
        { MenuComp }
      </div>
    </div>
  );
};

export default withRouter(memo(MobileMenu));