import React, { memo } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

interface Props {
  Chinese?: string;
  English?: string | JSX.Element;
  type?: 'line' | 'subtitle';
}

const Headline = (props: Props): JSX.Element => {
  const {
    Chinese = '',
    English = '',
    type = 'line',
  } = props;

  if (type === 'subtitle') {
    return (
      <div className={classNames(styles.headline, styles.subtitle)}>
        <div className={styles.eng}>{English}</div>
        <div className={styles.chinese}>{Chinese}</div>
      </div>
    );
  }

  return (
    <div className={classNames(styles.headline, styles.line)}>
      <span className={styles.chinese}>{Chinese}</span>
      <span className={styles.eng}>{English}</span>
    </div>
  );
};

export default memo(Headline);