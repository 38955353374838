import React, { memo } from 'react';
import { map, get } from 'lodash';
import { getValue } from '@@/utils';
import ManagementItem from './ManagementItem';
import styles from './index.module.scss';

interface Props {
  data: any[];
  onClickItem(...arg: any[]): void;
}

const ManagementList = (props: Props): JSX.Element => {
  const { data, onClickItem = () => {} } = props;

  return (
    <div className={styles.managementList}>
      {
        map(data, (item, idx): JSX.Element => {
          return (
            <ManagementItem
              key={get(item, 'articleId', idx)}
              className={styles.item}
              {...item}
              id={getValue(item, 'articleId')}
              onClick={onClickItem}
            />
          );
        })
      }
    </div>
  );
};

export default memo(ManagementList);