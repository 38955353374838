import React, { PureComponent } from 'react';
import { get, map, isArray } from 'lodash';
import classNames from 'classnames';
import defaultImg from '@@/assets/characterIcon.png';
import Headline from '../Headline';
import { mapData } from './utils';
import styles from './index.module.scss';

interface Props {
  title?: {
    chinese: string;
    eng: string;
  }
  nav?: any[];
  history?: any;
}

@mapData
class Sidebar extends PureComponent<Props> {
  onClickLevel(level: string) {
    const { history } = this.props;
    const path = get(level, 'path', '');
    console.log('level', level);
    if (path) {
      history.push(path);
    }
  }

  level1render(level1: any): JSX.Element {
    return (
      <div
        className={
          classNames(
            styles.level1Text,
            get(level1, 'path', '') ? styles.canClick : '',
            get(level1, 'selected', false) ? styles.active : ''
          )
        }
        onClick={() => { this.onClickLevel(level1); }}
      >
        { get(level1, 'text', '') }
      </div>
    )
  }

  level2render(level2: any, idx2: number): JSX.Element {
    return (
      <div
        className={
          classNames(
            styles.level2,
            get(level2, 'path', '') ? styles.canClick : '',
            get(level2, 'selected', false) ? styles.active : ''
          )
        }
        key={`level2-${idx2}`}
        onClick={() => { this.onClickLevel(level2); }}
      >
        { get(level2, 'text', '') }
      </div>
    );
  }

  public render() {
    const { title, nav } = this.props;
    const chinese = get(title, 'chinese', '');
    const eng = get(title, 'eng', '');
    return (
      <div className={styles.sidebar}>
        <Headline
          type="subtitle"
          English={eng}
          Chinese={chinese}
        />
        <div className={styles.line} />
        <div className={styles.nav}>
          {
            map(nav, (level1, idx1): JSX.Element => {
              const children = get(level1, 'children');
              return (
                <div className={styles.level1} key={`level1-${idx1}`}>
                  { this.level1render(level1) }
                  {
                    isArray(children)
                      ? (
                        <div className={styles.level1Children}>
                          {
                            map(children, (level2, idx2): JSX.Element => {
                              return this.level2render(level2, idx2);
                            })
                          }
                        </div>
                      )
                      : null
                  }
                </div>
              );
            })
          }
        </div>
        <div
          className={styles.img}
          style={{ backgroundImage: `url(${defaultImg})` }}
        />
      </div>
    );
  }
}

export default Sidebar;