import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import { CardList } from '../../../ContactUs';
import styles from './index.module.scss';

const mockData = [
  {
    icon: 'iconbofang1',
    msg: [
      '负责人',
      '柴洁莹',
    ],
  },
  {
    icon: 'iconbofang1',
    msg: [
      'E-MAIL',
      '69783882@QQ.COM',
    ],
  },
  {
    icon: 'iconbofang1',
    msg: [
      '固定电话',
      '0571-85192222',
    ],
  },
  {
    icon: 'iconbofang1',
    msg: [
      '地址',
      '杭州市延安路515号',
      '国信大厦905',
    ],
  },
];

interface Props {
  styleType?: 'strick' | 'filled';
  className?: string;
}

const ContactUsComp = (props: Props): JSX.Element => {
  const { className, styleType = 'filled' } = props;

  const getStylesByType = useCallback((type): string => {
    let styleClassName = '';
    switch(type) {
      case 'filled':
        styleClassName = styles.filled;
        break;
      case 'strick':
        styleClassName = styles.strick;
        break;
      default:
        styleClassName = styles.filled;
    }
    return styleClassName;
  }, []);

  return (
    <CardList
      className={classNames(getStylesByType(styleType), className)}
      data={mockData}
    />
  );
};

export default memo(ContactUsComp);