import React, { memo, useRef, useCallback } from 'react';
import { map } from 'lodash';
import $ from 'jquery';
import classNames from 'classnames';
import { getValue } from '@@/utils';
import Icon from '../../Icon/Icon';
import EventsAxiosItem from './EventsAxiosItem';
import styles from './index.module.scss';

interface Props {
  data: any[];
  className?: string;
}

const EventsAxios = (props: Props): JSX.Element => {
  const { data =[], className } = props;

  const refContent: any = useRef(null);

  const handleClickLeft = useCallback(() => {
    if (refContent.current) {
      $(refContent.current).scrollLeft(refContent.current.scrollLeft - 127);
    }
  }, []);

  const handleClickRight = useCallback(() => {
    if (refContent.current) {
      $(refContent.current).scrollLeft(refContent.current.scrollLeft + 127);
    }
  }, []);

  return (
    <div className={classNames(styles.event, className)}>
      <div className={styles.content}  ref={refContent}>
        <div className={styles.list}>
          {
            map(data, (item, idx): JSX.Element => {
              return (
                <EventsAxiosItem
                  key={getValue(item, 'id', idx)}
                  {...item}
                />
              );
            })
          }
        </div>
      </div>
      <div className={styles.line}></div>
      <Icon
        type="iconfanhui1"
        className={styles.left}
        onClick={handleClickLeft}
      />
      <Icon
        className={styles.right}
        type="icongengduo"
        onClick={handleClickRight}
      />
    </div>
  );
};

export default memo(EventsAxios);