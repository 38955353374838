import React, { memo } from 'react';
import classNames from 'classnames';

interface Props {
  type: string;
  className?: string
  onClick?(): void;
}

const Icon = (props: Props): JSX.Element => {
  const { type, className = '', onClick } = props;
  return (
    <i
      className={classNames('iconfont', type, className)}
      onClick={onClick}
    />
  );
};

export default memo(Icon);