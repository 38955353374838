import { isUndefined, forEach } from 'lodash';
import { Nil } from '../types/Nil';

function getValue<T>(object: any, path: string): T | Nil;
function getValue<T>(object: any, path: string | Nil, defaultValue: T): T;

/**
 * 根据路径获取对象的属性值
 * @param object
 * @param path
 * @param defaultValue
 */
function getValue(object: any, path: string | Nil, defaultValue?: any): any {
  if (!path) {
    if (object == null) {
      if (isUndefined(defaultValue)) {
        return object;
      }
      return defaultValue;
    }
    return object;
  }
  let value = getValue<any>(object, null, {});
  const pathArr = path.split('.');
  const pathCount = pathArr.length;
  forEach(pathArr, (item, index): void => {
    if (index === pathCount - 1) {
      value = getValue<any>(value[item], null, defaultValue);
      return;
    }
    value = getValue<any>(value[item], null, {});
  });
  return value;
}

export default getValue;
