import React, { PureComponent } from 'react';
import { get } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const mapper: Record<string, any> = {
  '/news': {
    routes: [
      {
        path: '/news',
        breadcrumbName: '新闻咨讯',
      },
      {
        path: '/news?',
        breadcrumbName: '新闻站',
      },
    ],
  },
  '/news/detail/:id': {
    routes: [
      {
        path: '/news',
        breadcrumbName: '新闻咨讯',
      },
      {
        path: '/news?',
        breadcrumbName: '新闻站',
      },
    ],
  },
  '/news/videos': {
    routes: [
      {
        path: '/news',
        breadcrumbName: '新闻咨讯',
      },
      {
        path: '/news/videos',
        breadcrumbName: '视频站',
      }
    ],
  },
  '/news/videos/detail/:id': {
    routes: [
      {
        path: '/news',
        breadcrumbName: '新闻咨讯',
      },
      {
        path: '/news/videos',
        breadcrumbName: '视频站',
      }
    ],
  },
  '/aboutUs': {
    routes: [
      {
        path: '/aboutUs',
        breadcrumbName: '关于我们',
      },
      {
        path: '/aboutUs?',
        breadcrumbName: '企业介绍',
      },
    ],
  },
  '/aboutHeader': {
    routes: [
      {
        path: '/aboutUs',
        breadcrumbName: '关于我们',
      },
      {
        path: '/aboutHeader',
        breadcrumbName: '负责人介绍',
      },
    ],
  },
  '/message': {
    routes: [
      {
        path: '/message',
        breadcrumbName: '信息披露',
      },
      {
        path: '/message?',
        breadcrumbName: '管理制度',
      },
    ],
  },
  '/message/detail/:id': {
    routes: [
      {
        path: '/message',
        breadcrumbName: '信息披露',
      },
      {
        path: '/message/detail/',
        breadcrumbName: '管理制度',
      }
    ],
  },
  '/message/report': {
    routes: [
      {
        path: '/message',
        breadcrumbName: '信息披露',
      },
      {
        path: '/message/report',
        breadcrumbName: '财务报告',
      },
    ],
  },
  '/message/report/detail/:id': {
    routes: [
      {
        path: '/message',
        breadcrumbName: '信息披露',
      },
      {
        path: '/message/report',
        breadcrumbName: '财务报告',
      },
    ],
  },
  '/message/files': {
    routes: [
      {
        path: '/message',
        breadcrumbName: '信息披露',
      },
      {
        path: '/message/files',
        breadcrumbName: '基金会文件',
      },
    ],
  },
  '/message/files/detail/:id': {
    routes: [
      {
        path: '/message',
        breadcrumbName: '信息披露',
      },
      {
        path: '/message/files',
        breadcrumbName: '基金会文件',
      },
    ],
  },
  '/sister': {
    routes: [
      {
        path: '/sister',
        breadcrumbName: '阳光姐姐',
      },
      {
        path: '/sister?',
        breadcrumbName: '大事记',
      },
    ],
  },
  '/sister/tender': {
    routes: [
      {
        path: '/sister',
        breadcrumbName: '阳光姐姐',
      },
      {
        path: '/sister/tender',
        breadcrumbName: '温情时刻',
      }
    ],
  },
  '/sister/detail/:id': {
    routes: [
      {
        path: '/sister',
        breadcrumbName: '阳光姐姐',
      },
      {
        path: '/sister/tender',
        breadcrumbName: '大事记',
      }
    ],
  },
  '/sister/tender/detail/:id': {
    routes: [
      {
        path: '/sister',
        breadcrumbName: '阳光姐姐',
      },
      {
        path: '/sister/tender',
        breadcrumbName: '温情时刻',
      }
    ],
  },
  '/sister/standard': {
    routes: [
      {
        path: '/sister',
        breadcrumbName: '阳光姐姐',
      },
      {
        path: '/sister/standard',
        breadcrumbName: '标准',
      }
    ],
  },
  '/sister/flow': {
    routes: [
      {
        path: '/sister',
        breadcrumbName: '阳光姐姐',
      },
      {
        path: '/sister/flow',
        breadcrumbName: '通道与流程',
      }
    ],
  },
  '/contactUs': {
    routes: [
      {
        path: '/contactUs',
        breadcrumbName: '联系我们',
      }
    ],
  },
  '/search': {
    routes: [
      {
        path: '/search',
        breadcrumbName: '首页',
      }
    ],
  },
};

interface Props extends RouteComponentProps {

}

export function mapData(WrapperComp: any): any {
  return withRouter(class extends PureComponent<Props> {
    render() {
      const { match } = this.props;
      const pathname: string = get(match, 'path', '');
      const data = mapper[pathname];

      return (
        <WrapperComp {...this.props} {...data} />
      );
    }
  })
}