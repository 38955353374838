import React, { PureComponent } from 'react';
import { Input } from 'antd';
import styles from './index.module.scss';

interface Props {
  onSearch?(...arg: any[]): void;
  defaultValue?: string;
}

class Search extends PureComponent<Props> {
  static defaultProps = {
    onSearch: () => {},
    defaultValue: '',
  };

  render(): JSX.Element {
    const { onSearch, defaultValue } = this.props;

    return (
      <div className={styles.search}>
        <Input.Search
          enterButton='搜索'
          onSearch={onSearch}
          defaultValue={defaultValue}
        />
      </div>
    );
  }
}

export default Search;