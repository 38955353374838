import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Icon from '../../../Icon/Icon';
import styles from './index.module.scss';

interface Props extends RouteComponentProps {
  id: number;
  date: string;
  year: string;
  title: string;
  content: string;
  className?: string;
  onClick?(...arg: any[]): void;
}

const ManagementItem = (props: Props): JSX.Element => {
  const { id, date, year, title, content, className, onClick = () => {} } = props;

  // let yearMonth = '';
  // let day = '';
  // if (/^([\d]*-[\d]*)-([\d]*)/.test(date)) {
  //   yearMonth = RegExp.$1;
  //   day = RegExp.$2;
  // }

  const handleToDetail = useCallback((): void => {
    onClick(id);
  }, [onClick, id]);

  return (
    <div
      className={classNames(styles.managementItem, className)}
      onClick={handleToDetail}
    >
      <div className={styles.date}>
        <div>{date}</div>
        <div className={styles.line} />
        <div>{year}</div>
      </div>
      <div className={styles.main}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <div className={styles.option}>
        <Icon
          type="iconbofang1"
          className={styles.icon}
        />
      </div>
    </div>
  );
};

export default memo(withRouter(ManagementItem));